import React, { useEffect, useState } from 'react';
import publicIp from 'public-ip';
import AdSense from 'react-adsense';
import './App.css';

import api from './services/api';

export default function App() {
  const [ipv4, setIpv4] = useState('');
  const [ipv6, setIpv6] = useState('');
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadIP();
  }, []);

  async function loadIP() {
    setLoading(true);

    try {
      const response_ipv4 = await publicIp.v4();
      const response_ipv6 = await publicIp.v6();
      const response = await api.get('/json');

      setIpv4(response_ipv4);
      setIpv6(response_ipv6);
      setInfo(response.data);
    } catch (err) {
      alert('Erro ao obter o seu IP.');
    }

    setLoading(false);
  }

  return (
    <div className="container">
      <header>
        <h1>Meu IP</h1>
      </header>

      <AdSense.Google client="ca-pub-2832909420983428" />

      <div className="content">
        <p className="ipv4">Seu IP (v4): {loading ? 'carregando...' : ipv4}</p>
        <p className="ipv6">Seu IP (v6): {loading ? 'carregando...' : ipv6}</p>

        <div className="containerGeo">
          <p className="text">
            Páis: {loading ? 'carregando...' : info?.country_name}
          </p>
          <p className="text">
            Estado: {loading ? 'carregando...' : info?.region}
          </p>
          <p className="text">
            Cidade: {loading ? 'carregando...' : info?.city}
          </p>
          <p className="text">
            Latitude: {loading ? 'carregando...' : info?.latitude}
          </p>
          <p className="text">
            Longitude: {loading ? 'carregando...' : info?.longitude}
          </p>
          <p className="text">
            Organização: {loading ? 'carregando...' : info?.org}
          </p>
        </div>

        <div className="containerInfo">
          <p className="text">O que é endereço IP?</p>
          <span>
            Um Endereço de Protocolo da Internet (Endereço IP), do inglês
            Internet Protocol address (IP address), é um rótulo numérico
            atribuído a cada dispositivo (computador, impressora, smartphone
            etc.) conectado a uma rede de computadores que utiliza o Protocolo
            de Internet para comunicação.
            <br />
            Um endereço IP serve a duas funções principais: identificação de
            interface de hospedeiro ou de rede e endereçamento de localização.{' '}
            <br />O Protocolo de Internet versão 4 (IPv4) define um endereço IP
            como um número de 32 bits.
            <br />
            Entretanto, devido ao crescimento da Internet e o esgotamento de
            endereços IPv4 disponíveis, uma nova versão do IP (IPv6), usando 128
            bits para o endereço IP, foi desenvolvida em 1995 e padronizada como
            RFC 2460 em 1998.
            <br />A implantação do IPv6 está em andamento desde meados de 2000.
            Para um melhor uso dos endereços de equipamentos em rede pelas
            pessoas, utiliza-se a forma de endereços de domínio, tal como
            "www.wikipedia.org". Cada endereço de domínio é convertido em um
            endereço IP pelo DNS (Domain Name System). Este processo de
            conversão é conhecido como "resolução de nomes".
          </span>

          <br />
          <a
            href="https://pt.wikipedia.org/wiki/Endere%C3%A7o_IP"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fonte: Wikipedia
          </a>
        </div>
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
